import _ from 'lodash'
import { connect, ConnectedProps } from 'react-redux'
import React, { Component } from 'react'
import { Form, Table, TreeSelect } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { updateSelectedOptionConveyors } from 'features/Version/redux/VersionActions'
import { deleteOption, saveOption } from 'features/Conveyor/redux/ConveyorActions'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import validateOptionsConfig from './validateOptionsConfig'
import EditableCell, { EditableContext } from '../EditableCell'
import './ValidateOptions.scss'
import { captureSentryError } from 'utils/helpers'
import { selectIsExternalAndHasEZGuideOption } from '../../redux/selectors'
import { formatOptionData, isEZGuideOption } from 'features/Version/helpers'
import { Store } from 'srcReducer'
import { IPriceValidation } from 'shared/types/swagger'

type ValidateOptionsProps = {
  versionId: string;
  totals: () => JSX.Element;
  /** AntD Form Validation Prop */
  form?: Form['props']['form'];
} & RouteComponentProps<{versionId: string}>

class ValidateOptions extends Component<ValidateOptionsProps & PropsFromRedux> {
  state = {
    optionData: [],
    optionDropdownSelected: {
      id: null,
      title: null,
    },
  }

  componentDidMount() {
    this.setOptionData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setOptionData()
    }
  }

  setOptionData = () => {
    const { options, isExternalAndHasEZGuideOption, optionsOrder } = this.props
    const optionData = formatOptionData<IPriceValidation>(options, optionsOrder).filter((option) => {
      if (isExternalAndHasEZGuideOption) {
        return isEZGuideOption(option)
      } else {
        return true
      }
    })

    // awfull hack to enforce enghrs are up to date in the UI
    // the issue was that if user modify the enghrs on the screen, then the quantity
    // then the UI would not reflect the enghrs that are calculated by the qty change despite being provided by BE ...
    const { form } = this.props
    optionData.map((option) => 
      {
        if (option.enghrs != undefined)
        {
          form.setFieldsValue({[`enghrs ${option.key}`] : option.enghrs})
          const test = form.getFieldsValue()
        }
      })

    this.setState({
      optionData,
    })
  }

  addOptionRow = async () => {
    const { optionDropdownSelected } = this.state
    const { saveOption } = this.props
    await saveOption(null, {
      optionalpricevalidationbyversionid: optionDropdownSelected.id,
      details: optionDropdownSelected.id == undefined ? optionDropdownSelected.title : null,
      quantity: 1,
    })
      .then((res) => {
        this.setState({
          optionDropdownSelected: {
            id: null,
            title: null,
          },
        })
      })
      .catch((e) => {
        debugger
      })
  }

  optionSelectDropdown = () => {
    const { optionDropdownSelected } = this.state
    return (
      <div className="validate-options__add-option-select">
        <TreeSelect
          id="price-validation-selection-options"
          dropdownClassName="options-table-select__tree"
          showSearch
          placeholder="Select an Option"
          value={optionDropdownSelected.id}
          searchPlaceholder="Search by Option Name"
          dropdownStyle={{ maxHeight: '40vh' }}
          filterTreeNode={(searchTerm, treeNode) =>
            treeNode?.props?.label?.toLowerCase().includes(searchTerm.toLowerCase() ?? true)
          }
          onSelect={(id, node) => {
            const { title } = node.props
            this.setState(  
              {
                optionDropdownSelected: {
                  id,
                  title
                },
              },
              () => this.addOptionRow()
            )
          }}
        >
          {this.renderOptionTreeNodes()}
        </TreeSelect>
      </div>
    )
  }

  renderColumns = () => {
    const columns = validateOptionsConfig(this.props).map((col) => {
      if (!col.visible) {
        return null
      }
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record, index) => {
          return {
            tableType: 'global_options',
            record,
            index,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            addonAfter: col.addonAfter,
            addonBefore: col.addonBefore,
            onChange: col.onChange,
            rules: col.rules,
          }
        },
      }
    })
    return columns.filter((c) => c)
  }

  renderOptionTreeNodes = () => {
    const { optionalpricevalidationbyversions } = this.props
    if (optionalpricevalidationbyversions != null)
    {
      const nonCategorizedOptions = Object.values(optionalpricevalidationbyversions)
                                          .filter((data) => data.legendcategory == undefined || data.legendcategory == '')
                                          .sort((a,b) => a.order - b.order - 1)
      const categorizedOptions = Object.values(optionalpricevalidationbyversions).filter((data) => data.legendcategory != undefined && data.legendcategory != '')
      const groupedByCategorizedOptions = _.groupBy(
        categorizedOptions,
        x => x.legendcategory)

      const { TreeNode } = TreeSelect
      const otherChoice =  [(
        <TreeNode
          key='globalother'
          title='Other'
          style={{ fontSize: '16px', fontWeight: 'normal', letterSpacing: '0' }}
          label='Other'
          />
      )]

      const nonCategorizedChoice = nonCategorizedOptions.map((data) => {
        var title = data.image != undefined
          ? <span>{data.label}<img src={data.image} style={{height: '44px', padding:'2px', margin:'-7px 0px -7px 0px', float:'right'}}/></span>
          : data.label
        return (
          <TreeNode
            key={data.id}
            value={data.id}
            title={title}
            label={data.label}
            style={{ fontSize: '16px', fontWeight: 'normal', letterSpacing: '0' }}
          />
      )})

      const categorizedChoice = _.map(groupedByCategorizedOptions,(groupedByCategorizedOption) => {
        const childNodes = groupedByCategorizedOption.sort((a,b) => a.order - b.order - 1).map((childData) => {
          return (
            <TreeNode
              key={childData.id}
              value={childData.id}
              title={childData.label}
              label={childData.label}
              style={{ fontSize: '16px', fontWeight: 'normal', letterSpacing: '0' }}
            />
          )
        })
        return (
          <TreeNode
            key={`parent-${groupedByCategorizedOption[0].legendcategory}`}
            value={groupedByCategorizedOption[0].legendcategory}
            title={groupedByCategorizedOption[0].legendcategory.toUpperCase()}
            selectable={childNodes ? false : true}
            style={{ fontSize: '14px', fontWeight: '600', letterSpacing: '1px' }}
          >
            {childNodes}
          </TreeNode>
        )
      })

      return otherChoice.concat(nonCategorizedChoice).concat(categorizedChoice)
    }

    return null;
  }

  tableFooter = () => {
    const { isactive, islocked, permissions, totals } = this.props
    return (
      <div className="validate-options__footer">
        {isactive && !islocked && permissions.includes('edit_options') ? (
          this.optionSelectDropdown()
        ) : (
          <div />
        )}
        <div>{totals()}</div>
      </div>
    )
  }

  render() {
    const { form } = this.props
    const { optionData } = this.state
    const components = {
      body: {
        cell: EditableCell,
      },
    }

    return (
      <React.Fragment>
        <EditableContext.Provider value={form}>
          <Table
            rowKey={(record) => record.key}
            className="validate-options"
            components={components}
            bordered
            dataSource={optionData}
            columns={this.renderColumns()}
            rowClassName={(_, i) => `editable-row-${i}`}
            pagination={false}
            footer={() => this.tableFooter()}
          />
        </EditableContext.Provider>
      </React.Fragment>
    )
  }
}

const EditableValidateOptions = Form.create({ name: 'validate-options' })(ValidateOptions)

const mapStateToProps = (state: Store, props: ValidateOptionsProps) => {
  try {
    const { versionId } = props.match.params
    const { isactive, islocked } = state.ProjectReducer.versions[versionId]
    const isExternalAndHasEZGuideOption = selectIsExternalAndHasEZGuideOption(state)

    return {
      conveyors: state.ConveyorReducer.conveyors,
      isactive,
      islocked,
      options: state.ConveyorReducer.options,
      optionsOrder: state.ConveyorReducer.optionsOrder,
      permissions: state.UserReducer.permissions,
      isExternalAndHasEZGuideOption,
      optionalpricevalidationbyversions: state.VersionReducer.optionalpricevalidationbyversions,
    }
  } catch (error) {
    captureSentryError(error, state)
  }
}

const mapDispatchToProps = {
  deleteOption,
  openConfirmModal,
  saveOption,
  updateSelectedOptionConveyors,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default withRouter(connector(EditableValidateOptions))
