import _, { filter } from 'lodash'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import ADP from 'awesome-debounce-promise'
import { withRouter } from 'react-router-dom'
import { Form, Table, TreeSelect } from 'antd'
import {
  deleteConveyorOption,
  saveConveyorOption,
  updateAllSharedConveyorOptions,
  updateConveyor,
} from 'features/Conveyor/redux/ConveyorActions'
import { selectVersion } from 'features/Version/redux/VersionActions'
import EditableCell, {
  EditableContext,
} from 'features/Version/components/ValidatePrice/components/EditableCell'
import MandatoryCell from './components/MandatoryCell'
import { ExpandIcon } from 'shared/components'
import { openConfirmModal } from 'shared/redux/ScreenActions'
import validateConveyorsConfig, {
  expandedOptionsConfig,
  selectableOptionsConfig,
} from './validateConveyorsConfig'
import './ValidateConveyors.scss'
import Wrapper from './components/Wrapper'

class ValidateConveyors extends Component {
  state = {
    conveyorData: [],
    optionDropdownSelected: {
      id: null,
      title: null,
    },
  }

  async componentDidMount() {
    const { conveyors, match, selectVersion } = this.props
    const { versionId } = match.params
    if (!Object.keys(conveyors).length) {
      await selectVersion(versionId, false ,false)
    } else {
      this.setConveyorData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.conveyors !== this.props.conveyors) {
      this.setConveyorData()
    }
  }

  setConveyorData = () => {
    const { conveyors } = this.props
    const conveyorData = this.getConveyorData(conveyors)

    // awfull hack to enforce enghrs are up to date in the UI
    // the issue was that if user modify the enghrs on the screen, then the quantity
    // then the UI would not reflect the enghrs that are calculated by the qty change despite being provided by BE ...
    const { form } = this.props
    _.map(conveyors,(conv) => 
      {
        _.map(conv.pricevalidations.nonmandatory,(pv) =>
        {
          if (pv.enghrs != undefined)
          {
            form.setFieldsValue({ [`enghrs ${pv.id}`] : pv.enghrs })
            const test = form.getFieldsValue()
          }
        })
      })

    this.setState({
      conveyorData,
    })
  }

  getConveyorData = (conveyors) => {
    const conveyorsData = _.map(conveyors, (conveyor) => {
      const {
        avgmuf,
        id,
        cost,
        discountpercentage,
        listprice,
        muf,
        name,
        netprice,
        order,
        ismufdiscounteditable,
      } = conveyor
      return {
        id,
        key: id,
        avgmuf,
        conveyorId: id,
        cost,
        discountpercentage,
        listprice,
        muf,
        name,
        netprice,
        order,
        ismufdiscounteditable,
      }
    })
    return conveyorsData.sort((a, b) => a.order - b.order)
  }

  addConveyorOptionRow = async (conveyorId) => {
    const { optionDropdownSelected } = this.state
    const { saveConveyorOption } = this.props

    await saveConveyorOption(conveyorId, null, {
      optionalpricevalidationbyversionid: optionDropdownSelected.id,
      details: optionDropdownSelected.id == undefined ? optionDropdownSelected.title : null,
      quantity: 1,
    })
      .then((res) => {
        this.setState({
          optionDropdownSelected: {
            id: null,
            title: null,
          },
        })
      })
      .catch((e) => {
        debugger
      })
  }

  optionSelectDropdown = (conveyorId) => {
    const { optionDropdownSelected } = this.state
    return (
      <div className="validate-options__add-option-select">
        <TreeSelect
          id="price-validation-selection-options"
          dropdownClassName="options-table-select__tree"
          showSearch
          placeholder="Select a Conveyor Option"
          value={optionDropdownSelected.id}
          searchPlaceholder="Search by Option Name"
          dropdownStyle={{ maxHeight: '40vh' }}
          filterTreeNode={(searchTerm, treeNode) =>
            treeNode?.props?.label?.toLowerCase().includes(searchTerm.toLowerCase() ?? true)
          }
          onSelect={(id, node) => {
            const { title } = node.props
            this.setState(
              {
                optionDropdownSelected: {
                  id,
                  title,
                },
              },
              () => this.addConveyorOptionRow(conveyorId)
            )
          }}
        >
          {this.renderOptionTreeNodes(conveyorId)}
        </TreeSelect>
      </div>
    )
  }

  renderOptionTreeNodes = (conveyorId) => {
    const { optionalpricevalidationbyversions } = this.props
    if (optionalpricevalidationbyversions != null)
    {
      const nonCategorizedOptions = optionalpricevalidationbyversions.filter((data) => data.legendcategory == undefined || data.legendcategory == '')
                                                                     .sort((a,b) => a.order - b.order - 1)
      const categorizedOptions = Map.groupBy(
        optionalpricevalidationbyversions.filter((data) => data.legendcategory != undefined && data.legendcategory != ''),
        data => data.legendcategory)

      const { TreeNode } = TreeSelect

      const otherChoice =  [(
        <TreeNode
          key={'ConveyorOther'+conveyorId}
          title='Other'
          style={{ fontSize: '16px', fontWeight: 'normal', letterSpacing: '0' }}
          label='Other'
        />
      )]

      const nonCategorizedChoice = nonCategorizedOptions.map((data) => {
        var title = data.image != undefined
          ? <span>{data.label}<img src={data.image} style={{ height: '44px', padding:'2px', margin:'-7px 0px -7px 0px', float:'right' }}/></span>
          : data.label
        return (
          <TreeNode
            key={data.id}
            value={data.id}
            title={title}
            label={data.label}
            style={{ fontSize: '16px', fontWeight: 'normal', letterSpacing: '0' }}
          />
      )})

      const categorizedChoice = Array.from(categorizedOptions).map(([category,options]) => {
        const childNodes = options.sort((a,b) => a.order - b.order - 1).map((childData) => {
          return (
            <TreeNode
              key={childData.id}
              value={childData.id}
              title={childData.label}
              label={childData.label}
              style={{ fontSize: '16px', fontWeight: 'normal', letterSpacing: '0' }}
            />
          )
        })
        return (
          <TreeNode
            key={`parent-${category}`}
            value={category}
            title={category.toUpperCase()}
            selectable={childNodes ? false : true}
            style={{ fontSize: '14px', fontWeight: '600', letterSpacing: '1px' }}
          >
            {childNodes}
          </TreeNode>
        )
      })

      return otherChoice.concat(nonCategorizedChoice).concat(categorizedChoice)
    }

    return null
  }

  renderColumns = () => {
    const { conveyors, isactive, islocked } = this.props
    const columns = validateConveyorsConfig(this.props, this.debouncedUpdateMufs).map((col) => {
      if (!col.visible) {
        return null
      }
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record, index) => {
          const conveyorOptions = conveyors?.[record?.conveyorId]?.pricevalidations?.sharedmuf || []
          const variableMufToBeDisplayed = _.some(conveyorOptions, (o) => o.muf !== record.muf)
            ? undefined
            : record.muf
          return {
            key: index,
            record: {
              ...record,
              muf: variableMufToBeDisplayed,
            },
            index,
            editable: col.editable,
            dataIndex: col.dataIndex,
            disabled: !isactive || islocked,
            title: col.title,
            addonAfter: col.addonAfter,
            addonBefore: col.addonBefore,
            onChange: col.onChange,
            rules: col.rules,
          }
        },
      }
    })
    return columns.filter((c) => c)
  }

  renderExpandedConveyorRow = (record) => {
    const { isactive, islocked, permissions, pricevalidations } = this.props
    const { conveyorId } = record
    const nonMandatoryComponents = {
      body: {
        cell: EditableCell,
      },
    }
    const mandatoryComponents = {
      body: {
        cell: MandatoryCell,
      },
    }

    const sharedMuf = pricevalidations?.[conveyorId]?.sharedmuf || []
    const mandatoryMuf = pricevalidations?.[conveyorId]?.mandatory || []
    const nonMandatoryMuf = pricevalidations?.[conveyorId]?.nonmandatory || []

    return (
      <div className="expanded-conveyor-options">
        <Table
          className="expanded-conveyor-options__shared"
          rowKey={(record) => record.id}
          dataSource={_.sortBy(sharedMuf, ['order'])}
          columns={expandedOptionsConfig(
            '',
            this.props,
            conveyorId,
            this.debouncedUpdateMufs,
            'sharedmuf'
          )}
          pagination={false}
        />
        <Table
          className="expanded-conveyor-options__mandatory"
          rowKey={(record) => record.id}
          data-conveyorid={conveyorId}
          components={mandatoryComponents}
          rowClassName={(record) => `table-row-${record.pricevalidationoptionname.toLowerCase()}`}
          dataSource={_.sortBy(mandatoryMuf, ['order'])}
          columns={expandedOptionsConfig(
            'Other MUF',
            this.props,
            conveyorId,
            this.debouncedUpdateMufs,
            'mandatory'
          )}
          pagination={false}
        />
        <Table
          className="expanded-conveyor-options__nonmandatory"
          components={nonMandatoryComponents}
          rowKey={(record) => record.id}
          dataSource={_.sortBy(nonMandatoryMuf, ['systemgenerated','pricevalidationoptionname'])}
          columns={this.renderConveyorOptionColumns(conveyorId)}
          pagination={false}
        />
        {isactive && permissions.includes('edit_options') && !islocked ? (
          this.optionSelectDropdown(record.conveyorId)
        ) : (
          <div />
        )}
      </div>
    )
  }

  renderConveyorOptionColumns = (conveyorId) => {
    const columns = selectableOptionsConfig(this.props, conveyorId).map((col) => {
      if (!col.visible) {
        return null
      }
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record, index) => {
          return {
            tableType: 'conveyor_options',
            record,
            index,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            addonAfter: col.addonAfter,
            addonBefore: col.addonBefore,
            onChange: col.onChange,
            rules: col.rules,
          }
        },
      }
    })
    return columns.filter((c) => c)
  }

  debouncedUpdateMufs = ADP(
    (conveyorId, key, id, value, optionType) =>
      this.updateConveyorOption(conveyorId, key, id, value, optionType),
    777,
    {
      key: (...args) => args[0],
    }
  )

  updateConveyorOption = async (conveyorId, key, id, value, optionType) => {
    const {
      conveyors,
      pricevalidations,
      saveConveyorOption,
      updateAllSharedConveyorOptions,
      updateConveyor,
      form: { setFieldsValue, resetFields, validateFields },
    } = this.props

    if (key === 'mufs') {
      setFieldsValue({ [`muf ${conveyorId}`]: value }, () => {
        validateFields([`muf ${conveyorId}`], (errors, values) => {
          if (!errors) {
            // Update ALL MUFs to value
            const sharedmuf = []
            const sharedmufValueToPatch = value === '' ? -1 : Number(value)
            const sharedmufValueToDisplay = value === '' ? undefined : Number(value)
            _.forEach(pricevalidations[conveyorId].sharedmuf, (o) => {
              // PATCH MUF API CALL
              setFieldsValue({ [`${key}_${o.id}`]: sharedmufValueToDisplay })
              sharedmuf.push({
                ...o,
                muf: sharedmufValueToPatch,
              })
            })
            const variablemufValueToPatch = value === '' ? 0 : Number(value)
            updateAllSharedConveyorOptions(conveyorId, sharedmuf).then(() =>
              updateConveyor(conveyorId, { muf: variablemufValueToPatch })
            )
          }
        })
      })
    } else {
      // Update ONE key for id to value
      validateFields([`${key}_${id}`], (errors, values) => {
        if (!errors) {
          (async () => {
            const conveyor = conveyors[conveyorId]
            if (key === 'muf' && optionType === 'sharedmuf' && conveyor[key] !== 0) {
              await updateConveyor(conveyorId, { [key]: 0 })
            }
            const transformedValue = value === '' ? -1 : value
            await saveConveyorOption(conveyorId, id, { [key]: transformedValue })
            resetFields()
          })()
        }
      })
    }
  }

  render() {
    const { totals, permissions } = this.props
    const { conveyorData } = this.state
    const components = {
      body: {
        cell: EditableCell,
        wrapper: Wrapper,
      },
    }
    const canExpand = permissions.includes('view_conveyordetails_prices')
    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          rowKey={(record) => record.key}
          className="validate-conveyors"
          components={components}
          bordered
          dataSource={conveyorData}
          columns={this.renderColumns()}
          rowClassName={() => 'editable-row'}
          pagination={false}
          {...(canExpand
            ? {
                footer: () => totals(),
                expandIcon: ExpandIcon,
                expandedRowRender: (record) => this.renderExpandedConveyorRow(record),
              }
            : {})}
        />
      </EditableContext.Provider>
    )
  }
}

const EditableValidateConveyors = Form.create({ name: 'validate-conveyors' })(ValidateConveyors)

/** @param {import('srcReducer').Store} state */
const mapStateToProps = (state, props) => {
  const { versionId } = props.match.params
  const { isactive, islocked } = state.ProjectReducer.versions[versionId]
  const { conveyors } = state.ConveyorReducer
  const pricevalidations = Object.values(conveyors).reduce((acc, cur) => {
    acc[cur.id] = cur.pricevalidations
    return acc
  }, {})

  return {
    conveyors,
    isactive,
    islocked,
    permissions: state.UserReducer.permissions,
    roles: state.UserReducer.roles,
    optionalpricevalidationbyversions: state.VersionReducer.optionalpricevalidationbyversions,
    pricevalidations,
  }
}

const mapDispatchToProps = {
  deleteConveyorOption,
  openConfirmModal,
  saveConveyorOption,
  selectVersion,
  updateAllSharedConveyorOptions,
  updateConveyor,
}

const connectedEditableValidateConveyors = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableValidateConveyors)

export default withRouter(connectedEditableValidateConveyors)
