import React from 'react'
import ADP from 'awesome-debounce-promise'
import { formatCurrency, ifDecimalRoundToNum } from 'utils/helpers'
import { Form, Input } from 'antd'
import { Icon } from 'shared/components'
import Colors from 'shared/constants/colors'

/**
 * @typedef {import('antd/lib/table').ColumnProps} ColumnProps
 *
 * @typedef {Object} ExtraColumnProps
 * @property {string} [align]
 * @property {boolean} [editable]
 * @property {boolean} [visible]
 * @property {any} [addonAfter]
 * @property {any} [addonBefore]
 * @property {any} [onChange]
 * @property {any} [rules]
 *
 * @typedef {ColumnProps & ExtraColumnProps} ColumnPropsPlus
 */

/** @returns {ColumnPropsPlus[]} */
export default function config(props, updateConveyorOption) {
  return [
    {
      title: 'Conveyor Name',
      dataIndex: 'name',
      editable: false,
      visible: true,
      align: 'left',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      width: '150px',
      editable: false,
      visible: props.permissions.includes('view_cost'),
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      key: 'muf',
      title: 'Variable MUF',
      dataIndex: 'muf',
      width: '130px',
      visible: props.permissions.includes('view_muf'),
      editable: props.permissions.includes('edit_muf'),
      className: 'muf',
      rules: [
        {
          transform: (value) => Number(value),
          validator: (rule, value, cb) => {
            try {
              if (isNaN(value)) {
                throw new Error('Must be a number.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      render: (value) => {
        return {
          children: ifDecimalRoundToNum(value, 2),
        }
      },
      onChange: (conveyorId, value) => {
        updateConveyorOption(conveyorId, 'mufs', null, value)
      },
    },
    {
      title: 'Avg. MUF',
      dataIndex: 'avgmuf',
      width: '100px',
      visible: props.permissions.includes('view_muf'),
      editable: false,
      // editable: props.permissions.includes('edit_muf'),
      className: 'avgmuf',
      render: (value) => {
        return {
          children: ifDecimalRoundToNum(value, 2),
        }
      },
    },
    {
      title: 'List Price',
      dataIndex: 'listprice',
      width: '150px',
      editable: false,
      visible: true,
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discountpercentage',
      width: '100px',
      visible: props.permissions.includes('view_discountpercentage'),
      editable: false,
      className: 'discount',
      addonAfter: '%',
      render: (value) => ({
        children: `${Math.round(value)}%`,
      }),
    },
    {
      title: 'Price',
      dataIndex: 'netprice',
      width: '150px',
      editable: false,
      visible: true,
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
  ]
}

export const expandedOptionsConfig = (
  title,
  props,
  conveyorId,
  updateConveyorOption,
  optionType
) => {
  const { isactive, islocked } = props

  const expandedConfig = [
    {
      title,
      dataIndex: 'pricevalidationoptionname',
      editable: false,
      visible: true,
      ellipsis: true,
      width: 160,
    },
    {
      title: 'Materials $',
      dataIndex: 'materialcost',
      width: 120,
      editable: false,
      visible: props.permissions.includes('view_cost'),
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: 'Lab Hrs',
      dataIndex: 'labhrs',
      width: 90,
      editable: false,
      visible: props.permissions.includes('view_cost'),
      render: (value) => {
        return {
          children: ifDecimalRoundToNum(value, 2),
        }
      },
    },
    {
      title: 'Lab $',
      dataIndex: 'labcost',
      width: 80,
      editable: false,
      visible: props.permissions.includes('view_cost'),
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalcost',
      width: 105,
      editable: false,
      visible: props.permissions.includes('view_cost'),
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: 'Calculated MUF',
      dataIndex: 'defaultmuf',
      width: 110,
      editable: false,
      visible: props.permissions.includes('view_muf'),
      render: (value) => {
        return {
          children: ifDecimalRoundToNum(value, 2),
        }
      },
    },
    {
      title: 'Variable MUF',
      dataIndex: 'muf',
      width: 95,
      visible: props.permissions.includes('view_muf'),
      editable: (record) => record.ismufdiscounteditable && props.permissions.includes('edit_muf'),
      className: 'muf',
      render: (value, record) => {
        const { getFieldDecorator } = props.form
        return {
          children: (
            <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 0 }}>
              {getFieldDecorator(`muf_${record.id}`, {
                initialValue: value ? ifDecimalRoundToNum(value) : undefined,
                rules: [
                  {
                    validator: (rule, value, cb) => {
                      try {
                        if (isNaN(Number(value))) {
                          throw new Error('Must be a number.')
                        } else if (Number(value) <= 0 && value !== '') {
                          throw new Error('Must be > 0.')
                        }
                        cb()
                      } catch (error) {
                        cb(error)
                      }
                    },
                  },
                ],
              })(
                <Input
                  disabled={!isactive || !record.ismufdiscounteditable || islocked}
                  className="variable-muf__input"
                  onChange={(e) =>
                    updateConveyorOption(conveyorId, 'muf', record.id, e.target.value, optionType)
                  }
                />
              )}
            </Form.Item>
          ),
        }
      },
    },
    {
      title: 'List Price',
      dataIndex: 'listprice',
      width: 80,
      visible: props.roles.includes('ExternalUser'),
      editable: false,
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discountpercentage',
      width: 105,
      visible: props.permissions.includes('view_discountpercentage'),
      editable: (record) => 
        record.ismufdiscounteditable && props.permissions.includes('edit_discountpercentage'),
      className: 'discountpercentage',
      render: (value, record) => {
        const { getFieldDecorator } = props.form
        if (
          !props.permissions.includes('edit_discountpercentage') ||
          !record.ismufdiscounteditable
        ) {
          return { children: <span>{ifDecimalRoundToNum(value)}%</span> }
        }
        return {
          children: (
            <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 0 }}>
              {getFieldDecorator(`discountpercentage_${record.id}`, {
                initialValue: ifDecimalRoundToNum(value),
                rules: [
                  {
                    validator: (rule, value, cb) => {
                      try {
                        if (isNaN(Number(value))) {
                          throw new Error('Must be a number.')
                        } else if (value === '') {
                          throw new Error('Discount is required.')
                        }
                        cb()
                      } catch (error) {
                        cb(error)
                      }
                    },
                  },
                ],
              })(
                <Input
                  disabled={!isactive || !record.ismufdiscounteditable || islocked}
                  className={`variable-discount__input ${isactive && !islocked ? '' : 'disabled'}`}
                  addonAfter="%"
                  onChange={(e) =>
                    updateConveyorOption(
                      conveyorId,
                      'discountpercentage',
                      record.id,
                      e.target.value,
                      optionType
                    )
                  }
                />
              )}
            </Form.Item>
          ),
        }
      },
    },
    {
      title: 'Price',
      dataIndex: 'netprice',
      editable: false,
      visible: true,
      width: 90,
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: '',
      dataIndex: 'empty',
      editable: false,
      visible: true,
      width: 33,
      render: '',
    },
  ]
  return expandedConfig.filter((c) => c.visible)
}

const onChangeFxn = (props, conveyorId, key, optionId, value) => {
  const { form, saveConveyorOption } = props
  form.validateFields([`${key} ${optionId}`], (error, row) => {
    if (error) {
      return false
    }
    const payload = key === 'pricevalidationoptionname' ? { details: value } : { [key]: value }
    saveConveyorOption(conveyorId, optionId, payload)
  })
}

const debouncedOnChangeFxn = ADP(
  (props, conveyorId, key, optionId, value) => onChangeFxn(props, conveyorId, key, optionId, value),
  800,
  {
    key: (...args) => `${args[0]}-${args[2]}`,
  }
)

export const selectableOptionsConfig = (props, conveyorId) => {
  const { deleteConveyorOption, isactive, islocked, permissions, optionalpricevalidationbyversions } = props

  const selectableConfig = [
    {
      title: 'Conveyor Options',
      dataIndex: 'pricevalidationoptionname',
      editable: (record) => permissions.includes('edit_options') 
                            && record.nameeditable
                            && !record.systemgenerated,
      rules: [{
        validator: (rule, value, cb) => {
          try {
            if (value.length <1 || value.length>30)
            {
              throw new Error('Option name length must be between 1 and 30.')
            }
            cb()
          } catch (error) {
            cb(error)
          }
        },
      }],
      visible: permissions.includes('view_options'),
      render: (text, row, index) => {
        return {
          children: <div>{row.details || row.pricevalidationoptionname}</div>,
        }
      },
      onChange: (optionId, value) =>
        debouncedOnChangeFxn(props, conveyorId, 'pricevalidationoptionname', optionId, value),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: 80,
      editable: (record) => permissions.includes('edit_quantity') && !record.systemgenerated,
      visible: permissions.includes('view_quantity'),
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Quantity is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionId, value) =>
        debouncedOnChangeFxn(props, conveyorId, 'quantity', optionId, value),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      width: 110,
      editable: (record) => permissions.includes('edit_cost') && !record.systemgenerated,
      visible: permissions.includes('view_cost'),
      addonBefore: '$',
      className: 'cost',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Cost is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
      onChange: (optionId, value) =>
        debouncedOnChangeFxn(props, conveyorId, 'cost', optionId, value),
    },
    {
      title: 'MUF',
      dataIndex: 'muf',
      width: 80,
      editable: (record) => record.ismufdiscounteditable 
                            && permissions.includes('edit_muf'),
      visible: permissions.includes('view_muf'),
      className: 'muf',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('MUF is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionId, value) =>
        debouncedOnChangeFxn(props, conveyorId, 'muf', optionId, value),
    },
    {
      title: 'Eng. Hrs',
      dataIndex: 'enghrs',
      width: 80,
      editable: (record) => record.ismufdiscounteditable 
                            && permissions.includes('edit_cost') 
                            && !record.systemgenerated,
      visible: permissions.includes('view_cost'),
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Eng Hrs is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionId, value) =>
        debouncedOnChangeFxn(props, conveyorId, 'enghrs', optionId, value),
    },
    {
      title: 'List Price',
      dataIndex: 'listprice',
      width: 100,
      editable: false,
      visible: true,
      addonBefore: '$',
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discountpercentage',
      width: 110,
      editable: (record) =>
        record.ismufdiscounteditable && permissions.includes('edit_discountpercentage'),
      visible: permissions.includes('view_discountpercentage'),
      addonAfter: '%',
      className: 'discount',
      rules: [
        {
          validator: (rule, value, cb) => {
            try {
              if (isNaN(Number(value))) {
                throw new Error('Must be a number.')
              } else if (value === '') {
                throw new Error('Discount is required.')
              }
              cb()
            } catch (error) {
              cb(error)
            }
          },
        },
      ],
      onChange: (optionId, value) =>
        debouncedOnChangeFxn(props, conveyorId, 'discountpercentage', optionId, value),
    },
    {
      title: 'Price',
      dataIndex: 'netprice',
      width: 95,
      editable: false,
      visible: true,
      render: (value) => {
        return {
          children: <span>{formatCurrency(value)}</span>,
        }
      },
    },
  ]

  if (isactive && !islocked) {
    selectableConfig.push({
      title: '',
      dataIndex: 'operation',
      editable: false,
      visible: permissions.includes('edit_options'),
      width: 10,
      render: (text, record, index) => {
        if (record.systemgenerated) return { children: null }
        return {
          children: (
            <div className="validation-table-actions">
              <Icon
                icon="delete"
                size={15}
                color={Colors.negative}
                onClick={() => {
                  props.openConfirmModal({
                    headerText: 'Delete Conveyor Option',
                    bodyText: `Are you sure you want to delete ${record.pricevalidationoptionname}?`,
                    onConfirm: () => deleteConveyorOption(conveyorId, record.id),
                  })
                }}
              />
            </div>
          ),
          props: {
            style: {
              overflow: 'visible',
            },
          },
        }
      },
    })
  }
  else 
  {
    selectableConfig.push({
      title: '',
      dataIndex: 'empty',
      editable: false,
      visible: true,
      width: 33,
      render: '',
    })
  }
  return selectableConfig.filter((c) => c.visible)
}
